<template>
  <div class="box">
    <el-breadcrumb class="m_tb_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{ name: 'schoolExperience', params: { current } }"
      >
        学校经验展示
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          name: 'schoolExperience',
          params: { current, selected },
        }"
        v-show="selected == '1603288561874968578'"
      >
        典型案例
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          name: 'schoolExperience',
          params: { current, selected },
        }"
        v-show="selected == '1603288609409015809'"
      >
        特色课程
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ resourceData.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bg_fff flex_row br_5 m_b_20">
      <img class="cover-img m_r_20" :src="resourceData.coverUrl" alt="" />
      <div class="course" style="width: 100%">
        <div class="fs_18 fw_bold m_in_10 m_t_15">{{ resourceData.name }}</div>
        <div v-for="item in resourceData.enclosureList" :key="item.id">
          <div
            class="course-list fs_16 flex_row p_in_10 pointer"
            @click="preview_click(item)"
          >
            <div>
              <span class="m_r_10">{{ item.name }}</span>
              <el-tag size="mini">{{ item.types }}</el-tag>
            </div>
            <i
              class="el-icon-star-off fs_18"
              @click.stop="collect_click(item)"
              v-if="item.collectId === -1"
            ></i>
            <i
              class="el-icon-star-on fs_18"
              @click.stop="collect_click(item)"
              style="color: #ff8540"
              v-else
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="course-info flex_row m_b_20">
      <div class="course-detail bg_fff br_5 p_in_20">
        <div class="flex_row m_b_10">
          <input
            type="radio"
            id="course-detail-label"
            name="course-info"
            value="course-detail-label"
            v-model="courseInfoLabel"
          />
          <label for="course-detail-label" class="fs_16 fw_bold m_r_20 pointer">
            课程简介
          </label>
          <input
            type="radio"
            id="course-resource-label"
            name="course-info"
            value="course-resource-label"
            v-model="courseInfoLabel"
          />
          <label for="course-resource-label" class="fs_16 fw_bold pointer">
            课程资源
          </label>
        </div>
        <div class="fs_14" v-show="courseInfoLabel === 'course-detail-label'">
          <div class="fs_16" v-html="resourceData.introduce"></div>
        </div>
        <div class="fs_14" v-show="courseInfoLabel === 'course-resource-label'">
          <div
            class="resource-box p_in_10 flex_row m_b_10"
            v-for="item in resourceData.enclosureList"
            :key="item.id"
          >
            <div class="resource fs_16">{{ item.name }}</div>
            <a
              :href="item.resourcesUrl"
              target="_blank"
              :download="item.resourcesUrl"
            >
              <button class="downloadBtn pointer">下载</button>
            </a>
          </div>
        </div>
      </div>
      <div class="studying bg_fff br_5 p_in_20">
        <div class="fs_18 fw_bold m_b_10">正在学习</div>
        <div class="flex_row_wrap" style="justify-content: center">
          <div
            class="student m_b_10"
            v-for="item in studentList"
            :key="item.id"
          >
            <img :src="avatar" alt="" />
            <div class="fs_16">{{ item.userName }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="预览"
      :visible.sync="previewDialog"
      destroy-on-close
      width="50%"
    >
      <div v-if="resource.type === '3'">
        <video-player
          class="video-player vjs-custom-skin m_b_20"
          ref="videoPlayer"
          :playsinline="playsinline"
          :options="playerOptions"
        />
      </div>
      <div v-if="resource.type === '2'" style="position: relative; width: 100%">
        <img :src="resource.coverUrl" :alt="resource.name" width="100%" />
        <audio ref="audioPlayer" :src="resource.resourcesUrl" controls></audio>
      </div>
      <div v-if="resource.type === '4'">
        <img :src="resource.resourcesUrl" :alt="resource.name" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import course from "assets/image/Group 651.png";
import avatar from "assets/image/avatar/femaleStudent.png";
import {
  getResourceDetail,
  getResourceLearn,
} from "@/api/resourceCenter/region";
import { collectResource } from "@/api/resourceCenter/school";

export default {
  name: "Detail",
  data() {
    return {
      course,
      avatar,
      courseInfoLabel: "course-detail-label",
      resourceData: {},
      previewDialog: false,
      resource: {},
      playsinline: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true, //是否显示控制条
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          volumeControl: true,
        },
      },
      studentList: [],
      current: null,
      selected: null,
    };
  },
  mounted() {
    let query = this.$route.query;
    let id = query.id;
    this.selected = query.selected;
    this.current = query.current;
    this.getResourceDetail(id);
  },
  methods: {
    getResourceDetail(id) {
      getResourceDetail({ id }).then((res) => {
        this.resourceData = res.data;
      });
      getResourceLearn({ resourceId: id }).then((res) => {
        this.studentList = res.data;
      });
    },
    preview_click(data) {
      this.resource = data;
      switch (data.type) {
        case "1":
          window.open(
            "http://139.155.146.142:8012/onlinePreview?url=" +
              encodeURIComponent(btoa(data.resourcesUrl))
          );
          break;
        case "2":
          this.previewDialog = true;
          break;
        case "4":
          this.previewDialog = true;
          break;
        case "3":
          this.playerOptions.sources[0].src = data.resourcesUrl;
          this.previewDialog = true;
          break;
      }
    },
    collect_click(data) {
      collectResource({
        id: data.id,
        type: data.collectId === -1 ? 1 : 0,
      }).then((res) => {
        if (data.collectId === -1) {
          if (res.code === 200) {
            this.resourceData.enclosureList.forEach((item) => {
              if (item.id === data.id) {
                item.collectId = 1;
              }
            });
            this.$message.success("收藏成功！");
          } else {
            this.$message.success("收藏失败！");
          }
        } else {
          if (res.code === 200) {
            this.resourceData.enclosureList.forEach((item) => {
              if (item.id === data.id) {
                item.collectId = -1;
              }
            });
            this.$message.success("取消收藏成功！");
          } else {
            this.$message.warning("取消收藏失败，请稍后重试！");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

.course-list {
  justify-content: space-between;
  align-items: center;
}

input {
  display: none;
}

.course input[type="radio"]:checked + label {
  background-color: rgba(73, 140, 240, 0.1);
}

.course-info {
  justify-content: space-between;
}

.course-detail {
  width: 62%;
}

.course-detail input[type="radio"]:checked + label::after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: #12c294;
}

.studying {
  width: 36%;
}

.student {
  margin-right: 40px;
}

.student:nth-child(3n) {
  margin-right: 0;
}

.student > div {
  width: 80px;
  text-align: center;
}

.student > img {
  width: 80px;
  height: 80px;
}

.resource-box {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}

.downloadBtn {
  width: 100px;
  height: 32px;
  background: linear-gradient(180deg, #64f4cf, #12c294 100%);
  border-radius: 4px;
  border: 0;
  color: #fff;
}

audio {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f1f3f4;
}

.cover-img {
  max-width: 300px;
  height: 200px;
  object-fit: cover;
}
</style>
